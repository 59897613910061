import ReactGA4 from "react-ga4";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Home from './components/pages/home';
import Notfound from './components/pages/notfound';
import Trend from './components/pages/trend';
import Trends from './components/pages/jptrend'
import StaticTrend from './components/pages/static'
import Qa from './components/pages/qa';
import Ad from './components/pages/ad';
import Inquiry from './components/pages/inquiry';
import Privacy from "./components/pages/privacy";

export default function App() {
  ReactGA4.send({ hitType: "pageview", page: window.location.pathname });

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path='trend'>
          <Route path=":trendId" element={<Trend />} />
        </Route>
        <Route path='trends' element={<Trends />}>
          <Route path=":trendsId" element={<Trends />} />
        </Route>
        <Route path='static'>
          <Route path=":staticId" element={<StaticTrend />} />
        </Route>
        <Route path='qa' element={<Qa />}/>
        <Route path='ad' element={<Ad />}/>
        <Route path='inquiry' element={<Inquiry />}/>
        <Route path='privacy' element={<Privacy />}/>
        <Route path='*' element={<Notfound />} />
      </Routes>
    </BrowserRouter>
  )
}