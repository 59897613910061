import { useState } from "react";
const Header = () => {
    const [openMenu, setOpenMenu] = useState(false);
    const menuFunction = () => setOpenMenu(!openMenu);
    return(
    <header className="border-b border-slate-300 lg:border-none">
        <div className="max-w-2xl mx-auto lg:max-w-7xl p-4 lg:pb-0">
            <div className="flex flex-col lg:flex-row justify-between text-slate-700 font-semibold text-sm max-w-2xl lg:max-w-7xl">
                <div className='text-center text-4xl flex justify-center '>
                    <a  href='/'
                        className="hover:bg-gray-200 hover:text-white rounded">
                        <img src='/hpbuzzsearch.png' alt="アイコン" className="w-[12rem] lg:w-60 p-2"></img>
                    </a>
                </div>
                <button onClick={menuFunction} className='absolute top-6 left-4 flex-initial lg:hidden z-20'>
                    <svg className="h-6 w-6 fill-current" viewBox="0 0 24 24">
                        <path d="M24 6h-24v-4h24v4zm0 4h-24v4h24v-4zm0 8h-24v4h24v-4z"/>
                    </svg>
                </button>
                { openMenu && (
                    <>
                    <div className="absolute top-0 left-0 w-full h-full bg-gray-300 z-10">
                    <nav className='flex flex-col gap-4 pt-20 px-4 flex-wrap list-none text-center'>
                    <a
                        href = '/'
                        className="h-20 bg-gray-800 flex items-center justify-center lg:text-right hover:bg-gray-600 text-white align-middle text-xl font-medium px-2 py-1 rounded hover:ring-4 hover:ring-gray-300">
                        今日のTOPツイート
                    </a>
                    <a
                        href = '/static/video'
                        className="h-20 bg-gray-800 flex items-center justify-center lg:text-right hover:bg-gray-600 text-white align-middle text-xl font-medium px-2 py-1 rounded hover:ring-4 hover:ring-gray-300">
                        世界のTOP動画
                    </a>
                    <a
                        href = '/static/image'
                        className="h-20 bg-gray-800 flex items-center justify-center lg:text-right hover:bg-gray-600 text-white align-middle text-xl font-medium px-2 py-1 rounded hover:ring-4 hover:ring-gray-300">
                        世界のTOP画像
                    </a>
                    <a
                        href = '/trends'
                        className="h-20 bg-gray-800 flex items-center justify-center lg:text-right hover:bg-gray-600 text-white align-middle text-xl font-medium px-2 py-1 rounded hover:ring-4 hover:ring-gray-300">
                        今日のトレンド
                    </a>
                    </nav>
                    </div>
                    </>
                )}
                <nav className='items-end lg:gap-3 pt-4 lg:pt-0 lg:justify-end lg:flex lg:flex-row flex-wrap list-none text-center hidden'>
                    <a
                        href = '/'
                        className="h-10 bg-gray-800 flex items-center justify-center lg:text-right hover:bg-gray-600 text-white align-middle text-xs font-medium px-2 py-1 rounded hover:ring-4 hover:ring-gray-300">
                        今日のTOPツイート
                    </a>
                    <a
                        href = '/static/video'
                        className="h-10 bg-gray-800 flex items-center justify-center lg:text-right hover:bg-gray-600 text-white align-middle text-xs font-medium px-2 py-1 rounded hover:ring-4 hover:ring-gray-300">
                        世界のTOP動画
                    </a>
                    <a
                        href = '/static/image'
                        className="h-10 bg-gray-800 flex items-center justify-center lg:text-right hover:bg-gray-600 text-white align-middle text-xs font-medium px-2 py-1 rounded hover:ring-4 hover:ring-gray-300">
                        世界のTOP画像
                    </a>
                </nav>
            </div>
        </div>
    </header>
    )
}
export default Header;
