
const SimpleSpinner = () => {
    return(
        <div className="flex justify-center mx-auto my-auto">
            <div className="animate-spin h-10 w-10 border-4 border-gray-500 rounded-full border-t-transparent"></div>
        </div>
    )
}

export default SimpleSpinner
