import Footer from "../views/footer"
import Header from "../views/header"
import TextView from "../views/textview"

const Qa = () =>{
    return(
        <>
        <Header />
        <TextView h={h} text={text} />
        <Footer />
        </>
    )
}

const h = '掲載の削除について'
const text = 'ツイートを削除されますと、2時間以内に当サイトから削除されます。検索エンジンに暫く残る場合があります。'

export default Qa