import Footer from "../views/footer"
import Header from "../views/header"
import TextView from "../views/textview"

const Ad = () =>{
    return(
        <>
        <Header />
        <TextView h={h} text={text}></TextView>
        <Footer />
        </>
    )
}

const h = '広告掲載について'
const text = '現在、当サイトは広告を募集しております。PV等の詳細についてはお問い合わせからお尋ね下さい。'
export default Ad