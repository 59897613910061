import TrendsTrends from '../parts/trendstrends'
import useSWR from 'swr';
import json from './trend.json'
const fetcher = (...args) => fetch(...args).then(res => res.json())

const TrendView = (props) => {
    if (process.env.NODE_ENV === 'production'){
        return <ProductionTrendView></ProductionTrendView>
    }
    console.log(json)
    return <DevelopmentTrendView></DevelopmentTrendView>
}

const ProductionTrendView = () =>{
    const { data, error } = useSWR('/api/trends', fetcher)
    if (error) return <div>error...</div>
    if (!data) return <div>loading...</div>
    return(
        <Templates data={data}></Templates>
    )
}

const DevelopmentTrendView = () => {
    return(
        <Templates data={json}></Templates>
    )
}

const Templates = (props) => {
    return(
        <div className='pt-12 pb-10 w-10/12 flex max-w-2xl center mx-auto flex-col gap-4 lg:max-w-7xl text-center items-center'>
            { props.data.new.map((item,index) => (
                <TrendsTrends key = {item.name} index = {index} item = {item}></TrendsTrends>
            ))}
        </div>
    )
}

export default TrendView
