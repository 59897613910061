import  { useState ,useEffect } from 'react';

const shuffleArray = (array) => {
  const cloneArray = [...array];
  const result = cloneArray.reduce((_,cur,idx) => {
    let rand = Math.floor(Math.random() * (idx + 1));
    cloneArray[idx] = cloneArray[rand]
    cloneArray[rand] = cur;
    return cloneArray
  })

  return result;
}

const TwitterTrends = (props) => {
    let arr = []
    props.data.new.map(item => {
      try {
        arr.push(item.name)
      }catch{
        return null
      }
      return null
    })
    arr = shuffleArray(arr)
    const [now, setNow] = useState(
    [
      {q:arr[0]},
      {q:arr[1]},
      {q:arr[2]},
      {q:arr[3]},
      {q:arr[4]},
      {q:arr[5]},
    ]
    )
    const [count,setCount] = useState(6)
    useEffect(() => {
      const intervalId = setInterval(() => {
        setCount( count => count +6 )
        play()
        setNow([
                {q:arr[(count+0)%arr.length]},
                {q:arr[(count+1)%arr.length]},
                {q:arr[(count+2)%arr.length]},
                {q:arr[(count+3)%arr.length]},
                {q:arr[(count+4)%arr.length]},
                {q:arr[(count+5)%arr.length]},
              ])
      }, 7000 );
      return () => { clearInterval(intervalId) };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[now])

    const play = () => {
      const element = document.querySelector("#progress");
      element.className="bg-gray-400 rounded-full"
      window.requestAnimationFrame((time)=> {
        window.requestAnimationFrame((time)=> {
          document.querySelector("#progress").className = "bg-gray-400 rounded-full animate-bar w-2";
        });
      });
    }
    return (
      <>
      <div className='max-w-2xl mx-auto lg:max-w-7xl px-4'>
      <div className="flex flex-col lg:flex-row items-center text-center">
        <div className='flex flex-row-reverse lg:flex-row	lg:items-end whitespace-nowrap pb-2 lg:pb-0 '>
          <div className="pl-4 w-full lg:pl-2">
            <a href='/trends'
              className='hover:bg-gray-800 hover:text-white p-2 rounded'>今日のトレンド</a>
          </div>
          <span className="text-xs font-medium inline-flex justify-center items-center lg:pl-4">
          <svg aria-hidden="true" className="mr-1 w-3 h-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z" clipRule="evenodd"></path></svg>
            { new Date( props.data.as_of).getHours() }時更新
          </span>
        </div>
        <div className="grid grid-cols-3 gap-1 lg:gap-3 lg:justify-start lg:flex lg:flex-row-reverse flex-wrap list-none text-center w-full">
          { now.map((item,index) => {
            const css ='h-10 bg-gray-800 flex items-center justify-center lg:text-right hover:bg-gray-600 text-white align-middle text-xs font-medium px-2 py-1 rounded hover:ring-4 hover:ring-gray-300'
            return(
              <a className={css} key={item.q} id={index} href={'/trend/' + encodeURIComponent(item.q)}>
                  <span>{item.q}</span>
              </a>
            )
          })}
          <div className="flex items-end w-2 bg-gray-200 rounded-full ">
            <div className="w-2 bg-gray-400 rounded-full animate-bar" id="progress"></div>
          </div>
        </div>
      </div>
      </div>
      </>
  );
}

export default TwitterTrends
