const Footer = () =>{
    return(
        <footer
            className=" pt-10 pb-10 border-t border-slate-300  text-slate-500  min-h-full ">
            <div
                className="w-5/6 mx-auto flex justify-between lg:max-w-7xl">

            <div
                className="p-4">
                Copyright © 2022 バズサーチ！
            </div>
            <div
                className="flex flex-col pr-4 text-end min-h-full">
                <a
                    className=""
                    href='/qa'>
                    削除について
                </a>
                <a
                    className=""
                    href='/ad'>
                    広告掲載について
                </a>
                <a
                    className=""
                    href='/inquiry'>
                    お問い合わせ
                </a>
                <a
                    className=""
                    href='/privacy'>
                    プライバシーポリシー
                </a>
            </div>
            </div>
        </footer>
    )
}

export default Footer