import TrendTimer from '../templates/trendtimer';
import Footer from '../views/footer';
import Header from '../views/header'
import TweetView from '../views/tweetview';

const Home = () => {
  return(
    <>
      <Header />
      <TrendTimer />
      <TweetView static={'jp'}/>
      <Footer />
    </>
  )
}
export default Home;