import AdsCard from "./googlead"

const TwitterCard = (props) => {
    const item = props.data
    const index = props.index
    return(
        <>
        {   ( (parseInt(index) % 8 === 0) && parseInt(index) !== 0 ) && (
            <div className='col-span-full h-auto'>
                <AdsCard />
            </div>
        )   }

        <a
           href={ "https://twitter.com/user/status/"+item.id_str }
           className=" h-full flex flex-col rounded-lg bg-gray-100 break-all lg:overflow-hidden focus:outline-none hover:bg-slate-200
           hover:animate-cardshadow shadow"
           target="_blank"
           rel="noopener noreferrer"
        >
            { item.extended_entities ? (
                <ImageCard item={item} />
            ):(
                <NoImageCard item={item} />
            )}
        </a>
        </>
    )
}
const ShortText = (props) => {
    const text = props.text
    if(text.length>40){
        return text.substring(0, 40) + '...'
    }else{
        return text
    }
}

const ShortName = (props) => {
    const text = props.text
    if(text.length>20){
        return text.substring(0, 20) + '...'
    }else{
        return text
    }
}

const FavAndRetweet = (props) =>{
    const item =props.item
    return(
        <div className="flex flex-col justify-center mr-auto">
            <p className="text-xs text-green-800 whitespace-nowrap">
            ♻{item.retweet_count}
            </p>
            <p className="text-xs text-red-600 whitespace-nowrap">
            ❤{item.favorite_count}
            </p>
        </div>
    )
}

const Verified = (props) => {
    const item = props.item
    return(
        <div className="flex items-center justify-evenly p-2 bg-sky-50">
            <FavAndRetweet item={item}/>
            <div className="flex justify-center items-center text-center w-full pl-2">
                <div className="text-xs text-blue-600 official pr-2">
                    <p>
                    ☑
                    </p>
                </div>
                <p className="text-xs text-gray-600 ">
                    <ShortName text={item.user.name}></ShortName>
                </p>
            </div>
        </div>
    )
}

const NotVerified = (props) => {
    const item = props.item
    return(
        <div className="flex items-center justify-evenly p-2 bg-gray-50">
            <FavAndRetweet item={item}/>
            <div className="flex justify-center text-center w-full">
                <p className="text-xs text-gray-600">
                    <ShortName text={item.user.name}></ShortName>
                </p>
            </div>
        </div>
    )
}

const UserProfs = (props) => {
    const item = props.item
    return(
        <>
        { (item.user.verified === true)?(
            <Verified item={item}/>
        ):(
            <NotVerified item={item}/>
        ) }
        </>
    )
}

const ImageCard = (props) => {
    const item = props.item
    return(
        <>
        <div className="flex-shrink-0">
            <img
                className="h-48 w-full object-cover lg:h-60"
                src={item.extended_entities.media[0].media_url_https}
                alt={item.full_text}
                loading="lazy"
            />
        </div>
        <div className="flex-1 flex flex-col justify-between">
            <div>
                <p className="text-gray-800 whitespace-pre-wrap text-xs p-2 lg:font-semibold lg:text-base lg:p-4">
                    <ShortText text={item.full_text} />
                </p>
            </div>
            <UserProfs item={item}></UserProfs>
        </div>
        </>
    )
}

const NoImageCard = (props) =>{
    const item = props.item
    return (
        <div className="flex-1 flex flex-col justify-between">
            <p className="text-gray-800 whitespace-pre-wrap text-sm p-4 lg:font-semibold lg:text-xl lg:p-6">
                {item.full_text}
            </p>
            <UserProfs item={item}></UserProfs>
        </div>
    )
}

export default TwitterCard
