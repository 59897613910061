import Footer from '../views/footer';
import Header from '../views/header'
import TrendView from '../views/trendview';

const Home = () => {
  return(
    <>
      <Header />
      <TrendView static={'jp'}/>
      <Footer />
    </>
  )
}
export default Home;