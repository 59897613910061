import Header from '../views/header';
import TweetView from '../views/tweetview';
import TrendTimer from '../templates/trendtimer';
import { useParams } from 'react-router-dom'
import Footer from '../views/footer';

function Trend() {
  const { staticId } = useParams();

  return (
    <>
      <Header />
      <TrendTimer></TrendTimer>
      <TweetView static={staticId}/>
      <Footer />
    </>
  )
}

export default Trend