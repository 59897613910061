import Footer from "../views/footer"
import Header from "../views/header"
import TextView from "../views/textview"
const Inquiry = () =>{
    return(
        <>
        <Header />
        <TextView h={h}text={text}></TextView>
        <Footer />
        </>
    )
}
const h = 'お問い合わせ'
const text = 'お手数ですが、inquiry@buzz-buzz-buzz.com からお問い合わせ下さい。'

export default Inquiry