import AdsCard from "./googlead"

const TrendsTrends = (props) =>{
    const index = props.index
    const item = props.item
    return (
        <>
            { ( (parseInt(index) % 16 === 0) && parseInt(index) !== 0 ) && (
                <div className='w-full h-auto'>
                    <AdsCard></AdsCard>
                </div>
            )}
            { (parseInt(index) %2 === 1)?(
            <a
                key={item.name}
                href={'/trend/' + encodeURIComponent(item.name)}
                className='w-full bg-gray-100 py-4 hover:bg-gray-800 hover:text-white text-xl rounded-lg'
                >
                {index+1}位:{item.name}
            </a>
            ):(
            <a
                key={item.name}
                href={'/trend/' + encodeURIComponent(item.name)}
                className='w-full bg-gray-300 py-4 hover:bg-gray-800 hover:text-white text-xl rounded-lg'
                >
                {index+1}位:{item.name}
            </a>
            )}
        </>
    )
}

export default TrendsTrends