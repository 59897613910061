const TextView =(props) =>{
    return(
        <>
        <div className="w-5/6 lg:max-w-7xl items-center mx-auto my-auto p-4">
            <div
                className="text-2xl">
                {props.h}
            </div>
            <div
                className=""
                >
            {props.text}
            </div>
        </div>
        </>
    )
}

export default TextView