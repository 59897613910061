import TwitterCard from '../parts/tweetcard'
import useSWR from 'swr'
import SimpleSpinner from '../parts/simplespinner'
import json from './tweetdata.json'
import TextView from './textview'

const fetcher = (...args) => fetch(...args).then(res => res.json())
const percentEncode = (str) => {
  return str.replace(/!/g, '%21')
    .replace(/\*/g, '%2A')
    .replace(/'/g, '%27')
    .replace(/\(/g, '%28')
    .replace(/\)/g, '%29')
    .replace(/#/g, '%23')
}

const TweetView = (props) => {
  let apipath
  if(props.path){
    apipath = '/api/trend/' + percentEncode(props.path)
  }else if(props.static){
    apipath = '/api/' + percentEncode(props.static)
  }
  if (process.env.NODE_ENV === 'production'){
    return <ProductionTweetView apipath = {apipath}/>
  }
  return <DevelopmentTweetView apipath = {apipath}/>
}

const ProductionTweetView = (props) => {
  const { data, error } = useSWR(props.apipath, fetcher)
  if (error) return <TextView h={'NOT FOUND...'} text={'ごめんなさい。エラーっぽいです。'} />
  if (!data) return <SimpleSpinner />
  if (!data.statuses){
    return <TextView h={'NOT FOUND...'} text={'ごめんなさい。エラーっぽいです。'} />
  }
  return(
    <Templates data={data}></Templates>
  )
}

const DevelopmentTweetView = (props) => {
  return(
    <Templates data={json}></Templates>
  )
}

const Templates = (props) =>{
  return (
    <article className="max-w-2xl mx-auto grid gap-4 grid-cols-2 p-4 lg:grid-cols-4 lg:max-w-7xl pb-24 overflow-hidden">
      { props.data.statuses.map((item,index) => (
          <TwitterCard data={item} key={item.id_str} index={index}></TwitterCard>
      ))}
    </article>
  )
}

export default TweetView
