import useSWR from 'swr';
import TwitterTrends from '../parts/tweettrends';
import json from '../views/trend.json';

const fetcher = (...args) => fetch(...args).then(res => res.json())

function TrendTimer(props) {
  if (process.env.NODE_ENV === 'production'){
    return <ProductionTrendTimer />
  }
  return <DevelopmentTrendTimer />
}

const ProductionTrendTimer = (props) => {
  const { data, error } = useSWR('/api/trends', fetcher)
  if (error) return <div>error...</div>
  if (!data) return <div>loading...</div>
  return(
    <Templates data = {data} />
  )
}

const DevelopmentTrendTimer = (props) => {
  return <Templates data = {json} />
}

const Templates = (props) => {
  return (
    <div className='mx-auto py-2 w-full border-b border-slate-300 lg:border-none'>
      <TwitterTrends data={ props.data }></TwitterTrends>
    </div>
  );
}

export default TrendTimer;
