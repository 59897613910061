import Header from '../views/header'
import Footer from '../views/footer';
import TextView from '../views/textview';

const Notfound = () => {
  return(
    <>
      <Header />
      <TextView h={'NOT FOUND...'} text={'ごめんなさい、何かエラーかも知れません。'} />
      <Footer />
    </>
  )
}
export default Notfound;