import Footer from "../views/footer"
import Header from "../views/header"

const Privacy = () =>{
    return(
        <>
        <Header />
        <div className="w-5/6 lg:max-w-7xl items-center mx-auto p-4">
            <div
                className="text-2xl">
                {h}
            </div>
            <div
                className=""
                >
            <span>{text1}</span><br></br>
            <span>{text2}</span><br></br>
            <span>{text3}</span>
            <a className="text-blue-600" href='https://policies.google.com/technologies/ads?gl=jp'>「広告 – ポリシーと規約 – Google」</a>
            <span>{text4}</span><br></br>
            <div
                className="text-2xl pt-4">
                {h2}
            </div>
            <span>{text5}</span><br></br>
            <span>{text6}</span>

           </div>
        </div>
        <Footer />
        </>
    )
}

const h = 'プライバシーポリシー'
const text1 = `当サイトでは第三者配信の広告サービス（Googleアドセンス、A8.net）を利用しており、ユーザーの興味に応じた商品やサービスの広告を表示するため、クッキー（Cookie）を使用しております。`
const text2 = 'クッキーを使用することで当サイトはお客様のコンピュータを識別できるようになりますが、お客様個人を特定できるものではありません。'
const text3 = 'Cookieを無効にする方法やGoogleアドセンスに関する詳細は'
const text4 = 'をご確認ください。'
const h2 = 'アクセス解析ツールについて'
const text5 ='当サイトでは、Googleによるアクセス解析ツール「Googleアナリティクス」を利用しています。このGoogleアナリティクスはトラフィックデータの収集のためにクッキー（Cookie）を使用しております。'
const text6='トラフィックデータは匿名で収集されており、個人を特定するものではありません。'
export default Privacy