import Header from '../views/header';
import TweetView from '../views/tweetview';
import TrendTimer from '../templates/trendtimer';
import Footer from '../views/footer';

import { useParams } from 'react-router-dom'

function Trend() {
  const { trendId } = useParams();

  return (
    <>
      <Header />
      <TrendTimer></TrendTimer>
      <TweetView path={trendId}/>
      <Footer />
    </>
  )
}

export default Trend